import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../components/common/Button"
import Github from "../images/sponsors/github.png"
import Devfolio from "../images/sponsors/Devfolio.png"
import Polygon from "../images/sponsors/Polygon.png"
import Solana from "../images/sponsors/Solana.png"
import Replit from "../images/sponsors/Replit.png"
import Filecoin from "../images/sponsors/Filecoin.png"
import Stickermule from "../images/sponsors/stickermule.png"
import Hackclub from "../images/sponsors/hackclub.png"
import Agora from "../images/sponsors/agora.png"

const Sponsers = () => {
  const itemsList = [
    {
      image: Devfolio,
    },
    {
      image: Polygon,
    },
    {
      image: Solana,
    },
    {
      image: Replit,
    },
    {
      image: Filecoin,
    },
    {
      // title: "Professional Drivers",
      // paragraph: "Regular Meetups",
      image: Stickermule,
    },
    {
      // title: "Professional Drivers",
      // paragraph: "Regular Meetups",
      image: Github,
    },
    {
      // title: "Professional Drivers",
      // paragraph: "Regular Meetups",
      image: Hackclub,
    },
    {
      // title: "Professional Drivers",
      // paragraph: "Presentations and Workshops ",
      image: Agora,
    },
  ]

  return (
    <>
      <section
        id="sponsors"
        className="relative pt-14 lg:pt-28 lg:pb-20 secondbg"
      >
        {/* Title Section */}
        <div className="text-left mx-auto space-y-10">
          <h1 className="w-3/4 mx-auto text-lg xl:text-6xl font-poppins font-medium  text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600 ">
          Previous  Sponsors
          </h1>
        </div>

        <div className="pt-20 w-4/5 grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-4  md:gap-x-10 sm:gap-y-16  gap-10  items-center justify-center mx-auto space-x-1">
          {itemsList.map((item, index) => {
            return (
              <div
                className="w-full flex flex-col space-y-2 items-center justify-center"
                key={index}
              >
                <div className="w-full bg-theme_two p-4 rounded-2xl shadow-md shadow-gray-600  flex items-center justify-center">
                  <img src={item.image} alt={item.title} className="w-36" />
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Sponsers
