import React from "react"
import { graphql, useStaticQuery, withPrefix } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Features from "../Hackathon/Features"
import Footer from "../components/common/Footer"
import Button from "../components/common/Button"
import AboutUs from "../Hackathon/AboutUs"
import Team from "../Hackathon/team"
import Sponsers from "../Homepages/Sponsors"
import FAQS from "../Homepages/faq/faqs"
import Welcome from "../images/welcome.mp4"
import Badge from "../Homepages/badge"
import HackLayout from "../components/hacksLayout"
import StarfieldAnimation from "react-starfield-animation"
import Schedule from "../Hackathon/schedule/Schedule"

const SchedulePage = () => {
  const SchedulePageQueryData = useStaticQuery(graphql`
    query SchedulePageQuery {
      Head: file(
        name: { eq: "scheduleImg" }
        absolutePath: { regex: "/images/hackathons/" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
    }
  `)

  const isBrowser = typeof window !== "undefined"

  const HeadImg = getImage(SchedulePageQueryData.Head)

  return (
    <>
      {isBrowser && (
        <StarfieldAnimation
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {/* <Cursor /> */}
      <HackLayout>
        <SEO title="DevMeet Community Hackathon " />
        <section
          id="header"
          className="relative w-full pt-20 pb-5 md:pt-18 xl:pt-6"
          // style={{
          //   backgroundImage: `url(${Banner})`,
          //   backgroundRepeat: "no-repeat",
          //   height: 500,
          // }}
        >
          <div className="w-4/5  mx-auto flex flex-col xl:flex-row items-center space-y-6 xl:space-y-0 xl:space-x-10">
            <div className="w-full flex flex-col items-start space-y-8">
              <GatsbyImage
                image={HeadImg}
                alt="Header"
                // className="animate-waving"
              />
            </div>
            <div className="w-full mt-12 mx-auto flex xl:items-center xl:justify-center">
              <div className="flex  flex-col items-center text-center space-y-6">
                <h2 className="text-2xl md:text-3xl xl:text-5xl font-Poppins font-bold tracking-normal text-white   xl:leading-snug">
                  🚀 Join the DevMeet Hackathon:
                  <span className="text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600">
                    Build, Collaborate, Innovate!
                  </span>{" "}
                  🌟
                </h2>
              </div>
            </div>
          </div>
        </section>

        <Team />
        <Footer />
      </HackLayout>
    </>
  )
}

export default SchedulePage
